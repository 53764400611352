import { DateTime } from 'luxon';

import { getTranslation } from '#/services/i18n';

export const validDateEntry = (schedule) => {
  const startsAt = schedule?.starts_at;
  const endsAt = schedule?.ends_at;
  const start = startsAt && DateTime.fromISO(startsAt);
  const end = endsAt && DateTime.fromISO(endsAt);
  const now = DateTime.now();

  if (start && start > now) {
    return {
      valid: false,
      type: 'comingSoon',
    };
  }

  if (end && end < now) {
    return { valid: false, type: 'expired' };
  }

  return {
    valid: true,
  };
};

export const validDateDaysLeft = (schedule) => {
  const now = DateTime.now();
  const endsAt = schedule?.ends_at;

  if (endsAt) {
    const endsAtDateTime = DateTime.fromISO(endsAt);
    const daysLeft = endsAtDateTime.diff(now, 'days').days;

    if (endsAtDateTime > now) {
      switch (true) {
        case daysLeft >= 0 && daysLeft <= 1:
          return `1 ${getTranslation('dayLeftToView')}`;
        case daysLeft > 1 && daysLeft <= 7:
          return `${Math.round(daysLeft)} ${getTranslation('daysLeftToView')}`;
        default:
          return null;
      }
    }

    return null;
  }

  return null;
};

export const getDrmInfo = (brightcoveInfo) => {
  const hasWidevineLicense = (source) => source && source.key_systems && source.key_systems['com.widevine.alpha'];

  const dashDrmSource = brightcoveInfo.sources.find(
    (source) => source.type === 'application/dash+xml' && hasWidevineLicense(source),
  );

  const hlsDRMSource = brightcoveInfo.sources.find(
    (source) => source.type === 'application/x-mpegURL' && hasWidevineLicense(source),
  );

  const dashNonDrmSource = brightcoveInfo.sources.find((source) => source.type === 'application/dash+xml');

  let drmSource;

  // Explicitly set the drmSource based on available licenses
  if (hasWidevineLicense(dashDrmSource)) {
    drmSource = dashDrmSource;
  } else if (hasWidevineLicense(hlsDRMSource)) {
    drmSource = hlsDRMSource;
  } else {
    drmSource = dashNonDrmSource;
  }

  if (!drmSource) {
    console.info('No DRM source detected, using first brightcove source');
    const contentSrc = brightcoveInfo.sources?.[0]?.src || '';

    return { src: contentSrc };
  }

  const { src } = drmSource;
  const licenseUrl = drmSource.key_systems?.['com.widevine.alpha']?.license_url || null;

  return { licenseUrl, src };
};
